import { FormSelect} from '@allsynx/components';
import { useState, useEffect } from 'react';
import { Link, useNavigate, useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { urls } from 'utils/environment-constants';
import { CompanyInfo } from 'types/company-info';
import { UserTypes } from 'enums/user-types';
import UsernamePasswordLoginForm from 'components/login/username-password-login-form';
import DemographicsLoginForm from 'components/login/demographics-login-form';
import MicrosoftLogin from 'components/login/microsoft-login';
import GoogleLogin from 'components/login/google-login';
import { fetchGraphQlAnonymous } from 'utils/api-util';
import { localStorageUtil } from 'utils/local-storage-util';
import { AccessLevels, AllowedAccess } from 'enums/access-levels';
import Error from 'components/errors/error';
import MaintenanceRobot from 'resources/robots/tools.png';

async function getCompanyInfo(webDirectory: string | undefined): Promise<CompanyInfo|null> {
	if (webDirectory) {
		const response = await fetchGraphQlAnonymous<CompanyInfo>(
				`query {
						companyInfo(webDirectory:"${webDirectory}") {
							companyId,
							companyName,
							isUseAuth,
							accessLevel,
							isActive,
							isSelfService,
							logoUrl,
							useGoogleSignIn,
							useMicrosoftSignIn,
							useBasicSignIn,
							userErrors
						}
					}`
			, "companyInfo"
		);

		return response?.resObj ?? null;
	} else {
		console.error(`Could not fetch company info for webDirectory: [${webDirectory}].`);
		return null;
	}
};

function TestEeLogin() {
	const setWebDirForLogo = useOutletContext<(src: string, id?: number) => void>();
	const [webDirectory, setWebDirectory] = useState("");
	const [companyInfo, setCompanyInfo] = useState<CompanyInfo|undefined>(undefined);
	const [isLoading, setIsLoading] = useState(false);
	const [needsFullSsn, setNeedsFullSsn] = useState(false);
	const [nextPage, setNextPage] = useState("");

	const { webDir } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();

	useEffect(() => {
		const updateCompanyInfo = async (webDirectoryParam: string | undefined) => {
			if (webDirectoryParam) {
				setWebDirectory(webDirectoryParam);
				setWebDirForLogo(webDirectoryParam);

				const info = await getCompanyInfo(webDirectoryParam);
				if (info) {
					if (!info.isUseAuth) {
						var newLocation = `${urls.tbhBaseUrl}/${webDirectoryParam}`;
						if (nextPage) {
							newLocation += `&NextPage=${nextPage}`;
						}
						window.location.href = newLocation;
					}

					setCompanyInfo(info);
					localStorageUtil.storeItem('webDir', webDirectoryParam);

					if (!info.isActive) {
						navigate("/errors/inactive", { replace: true });
					}
				} else {
					window.location.href = `${urls.tbhBaseUrl}/notfound.cfm`;
				}
			} else {
				console.error(`no webDirectoryParam [${webDirectoryParam}]`);
			}
		};

		updateCompanyInfo(webDir).catch(console.error);
	}, [webDir]);

	useEffect(() => {
		if (searchParams.get("NextPage")) {
			setNextPage(searchParams.get("NextPage") ?? "");
		}
	}, [searchParams])

  const renderView = () => {
	if (!companyInfo) {
		return <></>;
	}

	return (
		<div id="divCompanyAdminFormFields" className='divForm'>
			<DemographicsLoginForm webDirectory={webDirectory} isLoading={isLoading} setIsLoading={setIsLoading} needsFullSsn={needsFullSsn} setNeedsFullSsn={setNeedsFullSsn} nextPage={nextPage} />
			<Link to={{pathname: `/${webDirectory}`}}>Back</Link>
		</div>
	);
  }

  return (
	<>
		<p style={{fontWeight: 'bold'}}>Test Employee</p>
		{renderView()}
	</>
  );
}

export default TestEeLogin;