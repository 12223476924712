import { BorderSpinner, FormTextbox } from "@allsynx/components";
import { MfaContactSubTypes } from "enums/mfa-contact-sub-types";
import { MfaContactTypes } from "enums/mfa-contact-types";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useOutletContext, useSearchParams, Link } from "react-router-dom";
import { MfaContactOption } from "types/mfa-contact-option";
import { MfaResponse } from "types/mfa-response";
import { MfaValidateRequest } from "types/mfa-validate-request";
import { postAnonymous} from "utils/api-util"
import { localStorageUtil } from "utils/local-storage-util";
import { urls } from "utils/environment-constants";

function MfaVerify() {
	const CODE_LENGTH: number = 6;
	const CODE_MATCH_REGEX = /^[0-9]{6}$/g;
	const CODE_NOT_ALLOWED_CHARS_REGEX = /[^0-9]/g;

	const [duration, setDuration] = useState(0);
	const [attemptsRemaining, setAttemptsRemaining] = useState(0);
	const [contact, setContact] = useState<MfaContactOption>();
	const [isLoading, setIsLoading] = useState(false);
	const [code, setCode] = useState("");
	const [isCodeTouched, setIsCodeTouched] = useState(false);
	const [isCodeValid, setIsCodeValid] = useState(true);
	const [codeError, setCodeError] = useState("");
	const [sendError, setSendError] = useState("");
	const [isNewCodeSent, setIsNewCodeSent] = useState(false);
	const [isNeedsNewCode, setIsNeedsNewCode] = useState(false);

	const [searchParams, setSearchParams] = useSearchParams();

	const navigate = useNavigate();
	const setWebDirForLogo = useOutletContext<(src: string, id?: number) => void>();

	const processSendResponse = (response: MfaResponse) => {
		if (response?.mfaContact) {
			setContact(response.mfaContact);
		}
		if (response?.timeRemainingForCodeInSeconds) {
			setDuration(Math.floor(response.timeRemainingForCodeInSeconds / 60));
		}
		if (response?.attemptsRemaining) {
			setAttemptsRemaining(response.attemptsRemaining);
		} else {
			setAttemptsRemaining(0);
		}
		
		setIsNeedsNewCode(response?.needsNewCode ?? false);
	};

	useEffect(() => {
		setWebDirForLogo("");

		var mfaSendResponse = localStorageUtil.getItem<MfaResponse>('mfa-send-response');
		if (mfaSendResponse) {
			processSendResponse(mfaSendResponse);
		}
	}, []);

	const validate = (ignoreTouched: boolean, isSubmit: boolean): boolean => {
		var isValid = true;
		if (code === "" && (isCodeTouched || ignoreTouched)) {
			setCodeError("The Security Code field is required");
			setIsCodeValid(false);
			isValid = false;
		} else if (!code.match(CODE_MATCH_REGEX) && isSubmit) {
			setCodeError("The Security Code field should contain 6 digits.");
			setIsCodeValid(false);
			isValid = false;
		} else {
			setIsCodeValid(true);
			setCodeError("");
		}
	
		return isValid;
	  };
	
	const handleCodeChange = (value: string) => {
		value = value.replaceAll(CODE_NOT_ALLOWED_CHARS_REGEX, "");
		if (value.length > CODE_LENGTH) {
			value = value.substring(0, CODE_LENGTH);
		}
		setCode(value);
		setIsCodeTouched(true);
		validate(false, false);
	};

	const handleSend = async () => {
		setIsCodeTouched(true);
		setIsNewCodeSent(false);

		if (validate(true, true)) {
			setSendError("");
			setIsLoading(true);

			try {
				if (contact) {
					const mfaRequest: MfaValidateRequest = { mfaContact: contact, mfaCode: code };
					const response = await postAnonymous<MfaResponse>("/api/mfa/validate", JSON.stringify(mfaRequest));

					if (response?.ok && response?.resObj?.success && !response?.resObj?.errorMessage) {
						// "MFA Authentication" should just be a placeholder in case something went wrong, it should always be overwritten
						var authTypeQs = `${encodeURIComponent("MFA Verification Authentication")}`;
						if (searchParams?.get("AuthType")) {
							authTypeQs = `${searchParams.get("AuthType")}`;
						}

						var newLocation = `${urls.loginRedirect}?LoginToken=${response.resObj.token}&AuthType=${authTypeQs}`;
						if (searchParams?.get("NextPage")) {
							newLocation += `&NextPage=${searchParams?.get("NextPage")}`;
						}
						window.location.href = newLocation;
					} else {
						if (response?.resObj) {
							setAttemptsRemaining(response.resObj.attemptsRemaining);
							setSendError(response.resObj.errorMessage);
							setIsNeedsNewCode(response.resObj.needsNewCode);
						} else {
							setAttemptsRemaining(attemptsRemaining - 1);
							setSendError("Error logging in. Please try again.");
							setIsNeedsNewCode(false);
						}
						setIsLoading(false);
					}
				}
			} catch (error) {
				console.error(error);
				setSendError("Error logging in. Please try again.");
				setIsLoading(false);
			}
		}
	};

	const handleResend = async () => {
		if (contact) {
			setIsLoading(true);
			setSendError("");
			setCode("");
			setIsNeedsNewCode(false);

			var response = await postAnonymous<MfaResponse>(`/api/mfa/send`, JSON.stringify(contact));
			if (response?.ok && response?.resObj?.success && !response?.resObj?.errorMessage) {
				localStorageUtil.storeItem('mfa-send-response', response.resObj);
				
				processSendResponse(response.resObj);
				setIsLoading(false);
				setIsNewCodeSent(true);
			} else {
				setSendError(response?.resObj?.errorMessage ?? "Error sending code.");
				setIsLoading(false);
				setIsNewCodeSent(false);
			}
		}
	}

	const handleRetry = async () => {
		// "MFA Authentication" should just be a placeholder in case something went wrong, it should always be overwritten
		var authTypeQs = `${encodeURIComponent("MFA Options Authentication")}`;
		if (searchParams?.get("AuthType")) {
			authTypeQs = `${searchParams.get("AuthType")}`;
		}

		var navLocation = `/mfa/options?AuthType=${authTypeQs}`;
		if (searchParams?.get("NextPage")) {
			navLocation += `&NextPage=${searchParams.get("NextPage")}`;
		}
		navigate(navLocation, { replace: false });
	}


    
    return(
        <div id="divMfaContainer" className={`container security-code-container ${code?.length ? 'security-code-filled' : ''}`}>
            <div id='divMfaInstructions' className="instructions divForm">
                <div className='instructionHeader'>
                    <p>Enter Security Code</p>
                    <hr/>
                </div>
				
                {(contact && contact.type !== MfaContactTypes.AdminProvided && attemptsRemaining && !isNeedsNewCode) ? <p>Code sent to {contact?.value}.  Please enter the code below. Code is only valid for {duration} minutes.</p> : null}
				{(contact && contact.type === MfaContactTypes.AdminProvided && attemptsRemaining && !isNeedsNewCode) ? <p>Please enter the code below provided by your administrator. Code is only valid for {duration} minutes.</p> : null}
				{(!attemptsRemaining && !isNeedsNewCode && contact && contact.type !== MfaContactTypes.AdminProvided) ? <p>You have exceeded the maximum attempts.  Please click Send New Code to try again.</p> : null}
				{(!attemptsRemaining && !isNeedsNewCode && contact && contact.type == MfaContactTypes.AdminProvided) ? <p>You have exceeded the maximum attempts.  Please ask an admin for a new code and try again.</p> : null}
				{(isNeedsNewCode && contact && contact.type !== MfaContactTypes.AdminProvided) ? <p>Invalid code.  Please click Send New Code to try again.</p> : null}
                
				{(attemptsRemaining && !isNeedsNewCode) ?
					<>
						<FormTextbox
							type={"text"}
							placeholder={"Security Code"}
							id={"tbSecurityCode"}
							name="securityCode"
							onChange={(e) => {handleCodeChange(e.target.value);}}
							value={code}
							label="Security Code"
							isLabelHidden={true}
							isInvalid={!isCodeValid}
							errorMessage={codeError}
							touched={isCodeTouched}
							onBlur={() => validate(false, false)}
							disabled={isLoading}
							fieldWidth="full"
							onKeyDown={(e) => {
								if (e.key === "Enter") {
									e.preventDefault();
									e.stopPropagation();
									handleSend();
								}
							}}
						/>

						<div id='divMfaButton'>
							<Button id='btnSend' className='auth-button' variant='outline-primary' type='button' onClick={(e) => {e.preventDefault();e.stopPropagation();handleSend()}} disabled={isLoading}>{(isLoading) ? <BorderSpinner isFooterSavingSpinner/> : "Verify"}</Button>
						</div> 
					</>
				: null}

				{(contact && contact.type != MfaContactTypes.AdminProvided) ? <a id="lnkResend" href="javascript: void(0);" onClick={handleResend}>Send New Code</a> : null}
				
				{(!attemptsRemaining && !isNeedsNewCode && contact && contact.type == MfaContactTypes.AdminProvided) ? <Button id="btnRefresh" type="button" variant="outline-primary" className="auth-button" onClick={(e) => {e.preventDefault(); handleRetry();} }>Continue To Verification</Button> : null}
				
				<div>
					<Link to={{pathname: '/mfa/options', search: `?AuthType=${searchParams?.get('AuthType')}${(searchParams?.get('NextPage') ? `&NextPage=${searchParams.get('NextPage')}` : "")}`}}>Back</Link>
				</div>

				{(isNewCodeSent) ? <div>New code has been sent.</div> : null}
				{(sendError && attemptsRemaining && !isNeedsNewCode) ? <div id="divErrorMessage" className="Errors">{sendError}</div> : null}
            </div>
        </div>
    );
};

export default MfaVerify;