import Error from "components/errors/error";
import { useEffect } from "react";
import { Link, useOutletContext, useParams } from "react-router-dom";
import { localStorageUtil } from "utils/local-storage-util";
import { urls } from "utils/environment-constants";

function ErrorPage(props: { imageSrc: string, message: string, buttonText?: string, showTestEmployeeLink?: boolean }) {

	const { webDir } = useParams();

	const setWebDirForLogo = useOutletContext<(src: string, id?: number) => void>();
		
	useEffect(() => {
		if (webDir) {
			localStorageUtil.storeItem("webDir", webDir);
			setWebDirForLogo(webDir);
		} else {
			setWebDirForLogo("");
		}
	}, [webDir, setWebDirForLogo]);

	return (
		<div id="divLoginContainer" className="container">
			<div className='divForm'>
				<Error imageSrc={props.imageSrc} message={props.message} buttonText={props.buttonText} webDir={webDir} />
				{(props.showTestEmployeeLink && webDir) ? <Link style={{marginTop: '20px', display: 'block'}} to={{pathname:`/${webDir}/test`}}>Test Employee?</Link> : null}
			</div>
		</div>
	);
}

export default ErrorPage;