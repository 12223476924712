import { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import InaccesibleLogo from '../../resources/robots/error.png';
import { postAnonymous } from '../../utils/api-util';
import { HubLoginResponse } from "types/hub-login-response";
import { urls } from "utils/environment-constants";
import { OidcLoginRequest } from "types/oidc-login-request";
import { GetUserTypeNumeric, UserTypes } from "enums/user-types";
import DemographicsLoginForm from "components/login/demographics-login-form";
import { OidcAndBasicLoginRequest } from "types/oidc-basic-login-request";
import { EmployeeBasicLoginRequest } from "types/employee-basic-login-request";
import { OidcState } from "classes/oidc-state";
import { Button } from "react-bootstrap";

function OidcRedirect () {
	const [searchParams, setSearchParams] = useSearchParams();
	const { hash } = useLocation();
	const [idToken, setIdToken] = useState("");
	const [userType, setUserType] = useState<UserTypes | null>(null);
	const [oidcError, setOidcError] = useState("");
	const [loginError, setLoginError] = useState("");
	const [needsBasicAuth, setNeedsBasicAuth] = useState(false);
	const [userTypeSelectionRequired, setUserTypeSelectionRequired] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [needsFullSsn, setNeedsFullSsn] = useState(false);
	const [nextPage, setNextPage] = useState("");

	const navigate = useNavigate();
	const setWebDirForLogo = useOutletContext<(src: string, id?: number) => void>();

	const alternateBasicAuthSubmit = async (lastName: string, ssn: string, dob: string): Promise<{ status: number, ok: boolean, resObj: HubLoginResponse } | null> => {
		var oidcRequest: OidcLoginRequest = { idToken: idToken, userType: GetUserTypeNumeric(userType) };
		var basicRequest: EmployeeBasicLoginRequest = { lastName: lastName, ssn: ssn, dateOfBirth: dob, webDirectory: "oidc-get-from-token", rememberLogin: false, returnUrl: ""};
		var fullBasicRequestObject: OidcAndBasicLoginRequest = { oidcRequest: oidcRequest, basicRequest: basicRequest };


		return await postAnonymous<HubLoginResponse>("/api/oidc/microsoft/callback/basic", JSON.stringify(fullBasicRequestObject));
	};

	const tryLoginWithToken = async (request: OidcLoginRequest) => {
		setIsLoading(true);
		var response = await postAnonymous<HubLoginResponse>("/api/oidc/microsoft/callback", JSON.stringify(request));

		if (response?.ok && response?.resObj?.success && !response?.resObj?.needsMfa && (!response.resObj.errors || response.resObj.errors.length < 1)) {
			// TODO: for now the only oidc auth type is MS, later include logic for MS/Google/etc, also in else-if block for sending to mfa
			var newLocation = `${urls.loginRedirect}?LoginToken=${response.resObj.token}&AuthType=${encodeURIComponent("Microsoft Authentication")}`;
			if (nextPage) {
				newLocation += `&NextPage=${nextPage}`;
			}
			window.location.href = newLocation;
		} else if (response?.resObj?.needsMfa) {
			var navLocation = `/mfa/options?AuthType=${encodeURIComponent("Microsoft Authentication")}`;
			if (nextPage) {
				navLocation += `&NextPage=${nextPage}`;
			}
			navigate(navLocation, { replace: false });
		} else if (response?.resObj?.userTypeSelectionRequired != undefined && response.resObj.userTypeSelectionRequired) {
			setUserTypeSelectionRequired(true);
			setNeedsBasicAuth(false);
			setNeedsFullSsn(false);
			setIsLoading(false);
		} else if (response?.resObj?.singleAccountFound != undefined && !response.resObj.singleAccountFound && request.userType === GetUserTypeNumeric(UserTypes.Employee)) {
			setUserTypeSelectionRequired(false);
			setNeedsBasicAuth(true);
			setNeedsFullSsn(false);
			setIsLoading(false);
		} else if (response?.resObj?.additionalInfoNeeded != undefined && response.resObj.additionalInfoNeeded) {
			setUserTypeSelectionRequired(false);
			setNeedsBasicAuth(true);
			setNeedsFullSsn(true);
			setIsLoading(false);
		} else if (response?.resObj?.errors && response?.resObj?.errors?.length > 0) {
			setUserTypeSelectionRequired(false);
			setNeedsBasicAuth(false);
			setNeedsFullSsn(false);
			setLoginError(response.resObj.errors);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		setWebDirForLogo("");

		const tryLogin = async (token: string, loginUserType: UserTypes | null) => {
			await tryLoginWithToken({ idToken: token, userType: GetUserTypeNumeric(loginUserType) });
		};
		
		if (searchParams?.get("id_token")) {
			var token: string = searchParams?.get("id_token") ?? "";
			setIdToken(token);
			var usrType = null;
			if (searchParams?.get("state")) {
				var state = new OidcState();
				state.fromString(searchParams.get("state") ?? "");

				if (state.has("UserType")) {
					if (state.get("UserType")?.toLowerCase() === "employee") {
						usrType = UserTypes.Employee;
					} else if (state.get("UserType")?.toLowerCase() === "company_admin") {
						usrType = UserTypes.CompanyAdmin;
					} else if (state.get("UserType")?.toLowerCase() === "system_admin") {
						usrType = UserTypes.SystemAdmin;
					}
				}

				if (state.has("NextPage")) {
					setNextPage(state.get("NextPage") ?? "");
				}
			}
			setUserType(usrType);


			tryLogin(token, usrType).catch(console.error);
		}

		if (searchParams?.get("error_description")) {
			var errorDescription: string = searchParams?.get("error_description") ?? "";
			setOidcError(decodeURIComponent(errorDescription));
		}
	}, [searchParams]);

	useEffect(() => {
		setWebDirForLogo("");

		const tryLogin = async (token: string, loginUserType: UserTypes | null) => {
			await tryLoginWithToken({ idToken: token, userType: GetUserTypeNumeric(loginUserType) });
		};

		if (hash) {
			var token = "";
			var usrType = null;

			var hashFragment = hash;
			hashFragment = hashFragment.replaceAll("#", "");
			var splitHashFragment = hashFragment.split("&");
			
			splitHashFragment.forEach((item: string) => {
				var kvp = item.split("=");
				if (kvp.length == 2 && kvp[0] == "id_token") {
					token = kvp[1];
					setIdToken(token)
				} 
				if (kvp.length == 2 && kvp[0] == "state") {
					if (kvp[1]) {						
						var state = new OidcState();
						state.fromString(decodeURIComponent(kvp[1]));

						if (state.has("UserType")) {
							if (state.get("UserType")?.toLowerCase() === "employee") {
								usrType = UserTypes.Employee;
							} else if (state.get("UserType")?.toLowerCase() === "company_admin") {
								usrType = UserTypes.CompanyAdmin;
							} else if (state.get("UserType")?.toLowerCase() === "system_admin") {
								usrType = UserTypes.SystemAdmin;
							}
						}

						if (state.has("NextPage")) {
							setNextPage(state.get("NextPage") ?? "");
						}
					}
				} else if (kvp.length == 2 && kvp[0] == "error_description") {
					setOidcError(decodeURIComponent(kvp[1]));
				}
			});

			setUserType(usrType);

			tryLogin(token, usrType).catch(console.error);
		}
	}, [hash]);

	const handleUserTypeSelection = async (userType: UserTypes) => {
		setIsLoading(true);
		var oidcRequest: OidcLoginRequest = { idToken: idToken, userType: GetUserTypeNumeric(userType) };
		await tryLoginWithToken(oidcRequest);
	}

	return (
		<>
			<div id="divContainer" className="container">
            	<div id='divInstructions' className="instructions divForm">
					<div className='instructionHeader'>
						{(userTypeSelectionRequired) ? 	
							<div>	
								<p>We found multiple profiles with this email. Which profile would you like to login with?</p>		
								<hr/>	
							</div>
						:	
							<div>	
								<p>THE<i>benefits</i>HUB Login</p>	
								<hr/>
							</div>
						}
					</div>
					{(!oidcError && !loginError && !needsBasicAuth && !userTypeSelectionRequired) ? <div className="oidc-error"><p className="p-center">Processing request...</p></div> : null}
					{(oidcError) ? <div className="oidc-error"><p>There has been an error authenticating your user account.  Please try again.</p><br /><p>Error: [{oidcError}]</p><img src={InaccesibleLogo}/></div> : null}
					{(loginError) ? <div className="oidc-error"><p>{loginError}</p><img src={InaccesibleLogo}/></div> : null}
					{(userTypeSelectionRequired) ? 
					<div>			
						<Button 
							id="btnMicrosoftLogin" 
							className="loginButton oidcLoginButton" 
							onClick={() => handleUserTypeSelection(UserTypes.CompanyAdmin)} 
							type="button">
							<span className='user-type-selection-text'>Login as Company Administrator</span></Button>
						<Button 
							id="btnMicrosoftLogin" 
							className="loginButton oidcLoginButton" 
							onClick={() => handleUserTypeSelection(UserTypes.Employee)} 
							type="button">
							<span className='user-type-selection-text'>Login as Employee</span></Button>
					</div> : null }
					{(needsBasicAuth) ? <DemographicsLoginForm webDirectory={""} headerMessage={"For security purposes, additional information is required to access your account."} isLoading={isLoading} setIsLoading={setIsLoading} needsFullSsn={needsFullSsn} setNeedsFullSsn={setNeedsFullSsn} alternateSubmit={alternateBasicAuthSubmit} overrideAuthType={encodeURIComponent("Microsoft Authentication")} /> : null}
				</div>
			</div>
		</>
	);
}

export default OidcRedirect;