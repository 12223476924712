import { SideNavbar, MainContainer } from '@allsynx/components';
import { useEffect, useContext } from 'react';
import { NavigationContext } from "contexts/navigation-context";
import { localStorageUtil } from "utils/local-storage-util";
import { useParams, useOutletContext, Outlet } from "react-router-dom";

function Users() {
	const { sideBarNavItems, setFooterHasSideBar} = useContext(NavigationContext);
	const setWebDirForLogo = useOutletContext<(src: string, id?: number) => void>();
	
	useEffect(() => {
		setFooterHasSideBar(true);
	}, []);

	return (
		<>
			<SideNavbar navItems={sideBarNavItems} themeColor="blue"/> 
			<MainContainer>
					<Outlet context={setWebDirForLogo} />
			</MainContainer>
		</>
	);
}

export default Users;