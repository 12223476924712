import { Outlet } from "react-router-dom";
import { SideNavbar, MainContainer} from '@allsynx/components';
import { useEffect, useContext } from 'react';
import { NavigationContext } from "contexts/navigation-context";
import { localStorageUtil } from "utils/local-storage-util";
import { useParams, useOutletContext } from "react-router-dom";

function Settings() {
	const { sideBarNavItems,setFooterHasSideBar} = useContext(NavigationContext);
	const setWebDirForLogo = useOutletContext<(src: string, id?: number) => void>();

	const { webDir } = useParams();
	
	useEffect(() => {
		setFooterHasSideBar(true);
	}, []);

	useEffect(() => {
		if (webDir) {
			localStorageUtil.storeItem("webDir", webDir);
			setWebDirForLogo(webDir);
		} else {
			setWebDirForLogo("");
		}
	}, [webDir, setWebDirForLogo]);

	return (
		<>
			<SideNavbar navItems={sideBarNavItems} themeColor="blue"/> 
			<MainContainer>
				<Outlet />
			</MainContainer>
		</>
	);
}

export default Settings;