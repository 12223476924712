import { FormSelect} from '@allsynx/components';
import { useState, useEffect } from 'react';
import { Link, useNavigate, useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { urls } from 'utils/environment-constants';
import { CompanyInfo } from 'types/company-info';
import { UserTypes } from 'enums/user-types';
import UsernamePasswordLoginForm from 'components/login/username-password-login-form';
import DemographicsLoginForm from 'components/login/demographics-login-form';
import MicrosoftLogin from 'components/login/microsoft-login';
import GoogleLogin from 'components/login/google-login';
import { fetchGraphQlAnonymous } from 'utils/api-util';
import { localStorageUtil } from 'utils/local-storage-util';
import { AccessLevels, AllowedAccess } from 'enums/access-levels';
import Error from 'components/errors/error';
import MaintenanceRobot from 'resources/robots/tools.png';

async function getCompanyInfo(webDirectory: string | undefined): Promise<CompanyInfo|null> {
	if (webDirectory) {
		const response = await fetchGraphQlAnonymous<CompanyInfo>(
				`query {
						companyInfo(webDirectory:"${webDirectory}") {
							companyId,
							companyName,
							isUseAuth,
							accessLevel,
							isActive,
							isSelfService,
							logoUrl,
							useGoogleSignIn,
							useMicrosoftSignIn,
							useBasicSignIn,
							useCompanyAdminBasicSignIn,
							userErrors
						}
					}`
			, "companyInfo"
		);

		return response?.resObj ?? null;
	} else {
		console.error(`Could not fetch company info for webDirectory: [${webDirectory}].`);
		return null;
	}
};

function CompanyLogin() {
	const setWebDirForLogo = useOutletContext<(src: string, id?: number) => void>();
	const [webDirectory, setWebDirectory] = useState("");
	const [companyInfo, setCompanyInfo] = useState<CompanyInfo|undefined>(undefined);
	const [isEmployeeAllowed, setIsEmployeeAllowed] = useState(true);
	const [userType, setUserType] = useState(UserTypes.Employee);
	const [isLoading, setIsLoading] = useState(false);
	const [needsFullSsn, setNeedsFullSsn] = useState(false);
	const [nextPage, setNextPage] = useState("");

	const { webDir } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();

	useEffect(() => {
		const updateCompanyInfo = async (webDirectoryParam: string | undefined) => {
			if (webDirectoryParam) {
				setWebDirectory(webDirectoryParam);
				setWebDirForLogo(webDirectoryParam);

				const info = await getCompanyInfo(webDirectoryParam);
				if (info) {
					setCompanyInfo(info);
					localStorageUtil.storeItem('webDir', webDirectoryParam);

					if (!info?.isSelfService) {
						setIsEmployeeAllowed(false);
						setUserType(UserTypes.CompanyAdmin);
					}
					if (!info.isActive) {
						navigate("/errors/inactive", { replace: true });
					} else if (!AllowedAccess(info.accessLevel, AccessLevels.CompanyAdminAndUp) && !AllowedAccess(info.accessLevel, AccessLevels.EmployeeAndUp)) {
						navigate(`/errors/inaccessible/${webDirectoryParam}`, { replace: true });
					} else if (!info.isUseAuth) {
						var newLocation = `${urls.tbhBaseUrl}/${webDirectoryParam}`;
						if (nextPage) {
							newLocation += `&NextPage=${nextPage}`;
						}
						window.location.href = newLocation;
					}
				} else {
					window.location.href = `${urls.tbhBaseUrl}/notfound.cfm`;
				}
			} else {
				console.error(`no webDirectoryParam [${webDirectoryParam}]`);
			}
		};

		updateCompanyInfo(webDir).catch(console.error);
	}, [webDir]);

	useEffect(() => {
		if (userType === UserTypes.CompanyAdmin){
		document.getElementById("tbLastName")?.focus();
		}
	},[userType]);

	useEffect(() => {
		if (searchParams.get("NextPage")) {
			setNextPage(searchParams.get("NextPage") ?? "");
		}
	}, [searchParams])

  const renderView = () => {
	if (!companyInfo) {
		return <></>;
	}

	return (
		<div id="divCompanyAdminFormFields" className='divForm'>
			{
				(isEmployeeAllowed && !needsFullSsn) ? 
					<FormSelect
						id={"ddlUserType"}
						fieldWidth="full"
						name="userType"
						isLabelHidden={true}
						label={'User Type'}
						value={userType.toString()}
						onChange={(e) => setUserType(e.target.value as UserTypes)}>
							<option value={UserTypes.Employee}>Employee</option>
							<option value={UserTypes.CompanyAdmin}>Company Administrator</option>
					</FormSelect>
					: null
			}

			{
				(userType === UserTypes.CompanyAdmin && companyInfo.useCompanyAdminBasicSignIn) ?
					<UsernamePasswordLoginForm webDirectory={webDirectory} isLoading={isLoading} setIsLoading={setIsLoading} nextPage={nextPage} />
					: null
			}

			{
				(userType === UserTypes.Employee && !AllowedAccess(companyInfo.accessLevel, AccessLevels.EmployeeAndUp)) ?
					<Error imageSrc={MaintenanceRobot} message="This company is currently inaccessible. Please contact your system administrator for assistance." webDir={webDirectory} />
					: null
			}

			{
				(userType === UserTypes.Employee && AllowedAccess(companyInfo.accessLevel, AccessLevels.EmployeeAndUp) && companyInfo.useBasicSignIn) ?
					<DemographicsLoginForm webDirectory={webDirectory} isLoading={isLoading} setIsLoading={setIsLoading} needsFullSsn={needsFullSsn} setNeedsFullSsn={setNeedsFullSsn} nextPage={nextPage} />
					: null
			}

			{
				(
					(
						(userType === UserTypes.CompanyAdmin && AllowedAccess(companyInfo.accessLevel, AccessLevels.CompanyAdminAndUp)) ||
						(userType === UserTypes.Employee && AllowedAccess(companyInfo.accessLevel, AccessLevels.EmployeeAndUp))
					) &&
					companyInfo.useBasicSignIn &&
					!needsFullSsn &&
					(companyInfo.useMicrosoftSignIn || companyInfo.useGoogleSignIn)
				) ?
					<><div id="divOr">OR</div></>
					: null
			}

			{
				(
					(
						(userType === UserTypes.CompanyAdmin && AllowedAccess(companyInfo.accessLevel, AccessLevels.CompanyAdminAndUp)) ||
						(userType === UserTypes.Employee && AllowedAccess(companyInfo.accessLevel, AccessLevels.EmployeeAndUp))
					) &&
					!needsFullSsn &&
					companyInfo.useMicrosoftSignIn
				) ?
					<MicrosoftLogin companyId={companyInfo.companyId} isLoading={isLoading} setIsLoading={setIsLoading} userType={userType} nextPage={nextPage}  />
					: null
			}
			{
				(
					(
						// TODO: uncomment this line when ready for company admins via oidc
						//(userType === UserTypes.CompanyAdmin && AllowedAccess(companyInfo.accessLevel, AccessLevels.CompanyAdminAndUp)) ||
						(userType === UserTypes.Employee && AllowedAccess(companyInfo.accessLevel, AccessLevels.EmployeeAndUp))
					) &&
					!needsFullSsn &&
					companyInfo.useGoogleSignIn
				) ?
					<GoogleLogin companyId={companyInfo.companyId} isLoading={isLoading} setIsLoading={setIsLoading} nextPage={nextPage} />
					: null
			}

			{
				(userType === UserTypes.CompanyAdmin) ?
					<Link to={{pathname:`/${webDirectory}/test`}}>Test Employee?</Link>
					: null
			}
		</div>
	);
  }

  return (
	<>
		{renderView()}
	</>
  );
}

export default CompanyLogin;