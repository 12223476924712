import { FormEvent, useContext, useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { urls } from "utils/environment-constants";
import { FormSelect, HeaderRibbon, NavFooter, PageHeader, DatePicker, PillButton, TablePagination, SubHeader } from '@allsynx/components';
import { Container, Spinner, Table } from "react-bootstrap";
import { fetchGraphQlAnonymous, fetchGraphQlAuthenticated } from "utils/api-util";
import { UserTypes } from "enums/user-types";
import { NavigationContext } from "contexts/navigation-context";
import { CompanyInfo } from "types/company-info";
import { CompanyEmployeeRibbonDataInfo } from "types/ribbon-data";
import { NavItem } from "types/navigation/nav-item";
import { UserHistoryRecord, UserHistoryResponse } from "types/user-history-record";
import { HubUserInfo } from "types/hub-user-info";

async function getCompanyInfo(companyId: number): Promise<CompanyInfo|null> {
	if (companyId) {
		const response = await fetchGraphQlAnonymous<CompanyInfo>(
				`query {
						companyInfo(companyId: ${companyId}, webDirectory:"") {
							companyId,
							companyName,
							isSelfService,
							useGoogleSignIn
							useMicrosoftSignIn
							useBasicSignIn
						}
					}`
			, "companyInfo"
		);

		return response?.resObj ?? null;
	} else {
		console.error(`Could not fetch company info for companyId: [${companyId}].`);
		return null;
	}
};

async function getUserInfo(userId: string, userType: string): Promise<HubUserInfo|null> {
	if (userId) {
		const response = await fetchGraphQlAuthenticated<HubUserInfo>(
				`query {
						userInfo (userId: ${userId}, userType: ${userType.toUpperCase()}) {
							userId
							userName
							firstName
							lastName
							userType
							companyId
							loginStatus
							loginLocked
							oidcAssociations {
							  providerTypeId
							}
						}
				}`
			, "userInfo"
			, `${urls.tbhBaseUrl}/employees/index.cfm?message=You+are+not+authorized+to+access+that+page.`
		);

		return response?.resObj ?? null;
	} else {
		console.error(`Could not fetch user info for id: [${userId}].`);
		return null;
	}
};

async function getHistory(userId: number, userType: string, startDate: Date, endDate: Date, pageSize: number|null, pageNumber: number): Promise<UserHistoryResponse|null> {
	if (userId) {
		const response = await fetchGraphQlAuthenticated<UserHistoryResponse>(
			`query {
				userHistory (userId: ${userId}, userType: ${userType.toUpperCase()}, startDate: "${startDate.getMonth()+1}/${startDate.getDate()}/${startDate.getFullYear()}", endDate: "${endDate.getMonth()+1}/${endDate.getDate()}/${endDate.getFullYear()}", pageSize: ${pageSize ? pageSize : "null"}, pageNumber: ${pageNumber}) {
					userHistory {
						userHistoryId,
						action,
						actionDate,
						detail,
						modifiedByUserName,
						modifiedByUserTypeDescription
					},
					totalCount,
					userErrors
				}
			}`
			, "userHistory"
			, `${urls.tbhBaseUrl}/Menu${userType.toLowerCase().includes("company") ? "Admin" : "System"}.cfm?message=You+are+not+authorized+to+access+that+page.`
		);

		return response?.resObj ?? null;
	} else {
		console.error(`Could not fetch user info for id: [${userId}].`);
		return null;
	}
};

async function getRibbonData(companyId: number, employeeId: number): Promise<CompanyEmployeeRibbonDataInfo|null> {

	const response = await fetchGraphQlAuthenticated<CompanyEmployeeRibbonDataInfo>(
			`query {
					ribbonData(companyId:${companyId}, employeeId:${employeeId}) {
						errors
						employeeTags {
							tagValue
							tagDisplay
							tagClipBoardValue
							tagHover
						}
						companyTags {
							tagValue
							tagDisplay
							tagClipBoardValue
							tagHover
						}
					}
				}`
		, "ribbonData"
		, null
		, `${urls.membersApiBaseUrl}/graphql`
	);

	return response?.resObj ?? null;
};

function UserHistory() {

	const { setSideBarNavItems } = useContext(NavigationContext);
	const navigate = useNavigate();
	const setWebDirForLogo = useOutletContext<(src: string, id?: number) => void>();
	const loadNavData = async (userType: UserTypes) => {
		var navItems: NavItem[] = [{
			id: "systemMenu",
			displayName: "System Menu",
			href: "#",
			onClick: () => {
				var newUrl = `${urls.tbhBaseUrl}/MenuSystem.cfm`;
				window.location.replace(newUrl);
			}
		}];

		if (userType == UserTypes.CompanyAdmin) {
			navItems.push({
				id: "companyMenu",
				displayName: "Company Menu",
				href: "#",
				onClick: () => {
					var newUrl = `${urls.tbhBaseUrl}/MenuAdmin.cfm`;
					window.location.replace(newUrl);
				}
			});
		}
		setSideBarNavItems(navItems);
	}

	const todaysDate = new Date();
	const [isInitialLoading, setIsInitialLoading] = useState(true);
	const [userIdParam, setUserIdParam] = useState<number>(0);
	const [userTypeParam, setUserTypeParam] = useState<string>("");
	const [companyIdParam, setCompanyIdParam] = useState<number|undefined>(undefined);
	const [isFetching, setIsFetching] = useState(false);
	const [companyInfo, setCompanyInfo] = useState<CompanyInfo|null>(null);
	const [ribbonDataInfo, setRibbonDataInfo] = useState<CompanyEmployeeRibbonDataInfo|null>(null);
	const [selectedStartDate, setSelectedStartDate] = useState<Date>(new Date(todaysDate.getFullYear(), todaysDate.getMonth(), 1));
	const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());
	const [selectedPrevStartDate, setSelectedPrevStartDate] = useState<Date>(new Date(todaysDate.getFullYear(), todaysDate.getMonth(), 1));
	const [selectedPrevEndDate, setSelectedPrevEndDate] = useState<Date>(new Date());
	const [selectedPageSize, setSelectedPageSize] = useState<number|null>(10);
	const [selectedPageNumber, setSelectedPageNumber] = useState<number>(1);
	const [userHistoryRecords, setUserHistoryRecords] = useState<UserHistoryRecord[]>([]);
	const [totalRecordCount, setTotalRecordCount] = useState<number>(0);
	const [errors, setErrors] = useState<string[]|null>(null);
	const { userType, userId } = useParams();

	useEffect(() => {
		var userTypeString = "";
		if (userType?.length) {
			if (userType === "0") {
				userTypeString = "EMPLOYEE";
			} else if (userType === "1") {
				userTypeString = "COMPANY";
			} else if (userType === "2") {
				userTypeString = "SYSTEM";
			} else {
				userTypeString = userType.toUpperCase();
			}
		}
		setUserIdParam(userId ? +userId : 0);
		setUserTypeParam(userTypeString);
		loadPageData(userId ? +userId : 0, userTypeString).catch(console.error);
	}, [userId, userType]);

	const loadPageData = async (uId: number, uType: string) => {
		const userInfoResp = await getUserInfo(uId.toString(), uType);
		var companyId = userInfoResp?.companyId ? userInfoResp.companyId : undefined;

		if (companyId) {
			// load general company info
			const info = await getCompanyInfo(companyId);	
			if (info) {
				setCompanyInfo(info);
			}
			const ribbonDataInfo = await getRibbonData(companyId, 0);
			loadNavData(UserTypes.CompanyAdmin);
			setRibbonDataInfo(ribbonDataInfo);
			setWebDirForLogo("", companyId);
		} else {
			loadNavData(UserTypes.SystemAdmin);
			setRibbonDataInfo(null);
			setWebDirForLogo("");
		}

		setSelectedPrevStartDate(selectedStartDate);
		setSelectedPrevEndDate(selectedEndDate);
		const resp = await getHistory(uId, uType, selectedStartDate, selectedEndDate, selectedPageSize, 1);
		if (resp?.userHistory?.length) {
			setUserHistoryRecords(resp.userHistory);
			setTotalRecordCount(resp.totalCount);
		} else {
			setUserHistoryRecords([]);
			setTotalRecordCount(0);
			if (resp?.userErrors?.length) {
				setErrors(resp.userErrors)
			}
		}

		setIsInitialLoading(false);
	};

	const handleSearch = async (e: FormEvent<HTMLButtonElement>) => {
		e.preventDefault();
		e.stopPropagation();
		setIsFetching(true);
		setSelectedPageNumber(1);
		setSelectedPrevStartDate(selectedStartDate);
		setSelectedPrevEndDate(selectedEndDate);
		var resp = await getHistory(userIdParam, userTypeParam, selectedStartDate, selectedEndDate, selectedPageSize, 1);
		if(resp?.userHistory?.length) {	
			setUserHistoryRecords(resp.userHistory);
			setTotalRecordCount(resp.totalCount);
		} else {
			setUserHistoryRecords([]);
			setTotalRecordCount(0);
			if (resp?.userErrors?.length) {
				setErrors(resp.userErrors)
			}
		}
		setIsFetching(false);
	};

	const displayDate = (dt: Date): string => {
		var d = new Date(dt);
		var dateDisplay = d.toLocaleDateString("en-us", { year: "numeric", day: "2-digit", month: "2-digit"});
		var hours = d.getHours();
		var dayPeriod = hours < 12 ? "AM" : "PM";
		hours = hours % 12;
		var hoursDisplay = hours < 10 ? `0${hours}` : `${hours}`;
		var minutes = d.getMinutes();
		var minutesDisplay = minutes < 10 ? `0${minutes}` : `${minutes}`;
		var localeTimeSplit = d.toLocaleTimeString("en-us", {timeZoneName: "short"}).split(" ");
		var tz = localeTimeSplit[localeTimeSplit.length-1];
		
		return `${dateDisplay} ${hoursDisplay}:${minutesDisplay}&nbsp;${dayPeriod}&nbsp;${tz}`;
	};

	const handlePaginationChange = async (page: number) => {
		
		setIsFetching(true);
		setSelectedPageNumber(page);
		setSelectedStartDate(selectedPrevStartDate);
		setSelectedEndDate(selectedPrevEndDate);
		var resp = await getHistory(userIdParam, userTypeParam, selectedPrevStartDate, selectedPrevEndDate, selectedPageSize, page);
		if(resp?.userHistory?.length) {	
			setUserHistoryRecords(resp.userHistory);
			setTotalRecordCount(resp.totalCount);
		} else {
			setUserHistoryRecords([]);
			setTotalRecordCount(0);
			if (resp?.userErrors?.length) {
				setErrors(resp.userErrors)
			}
		}
		setIsFetching(false);
	};

	const handlePageSizeChange = async (value: string) => {
		setSelectedPageSize(value == "" ? null : +value);
		setSelectedPageNumber(1);
		setSelectedStartDate(selectedPrevStartDate);
		setSelectedEndDate(selectedPrevEndDate);
		setIsFetching(true);
		var resp = await getHistory(userIdParam, userTypeParam, selectedPrevStartDate, selectedPrevEndDate, value == "" ? null : +value, 1);
		if(resp?.userHistory?.length) {	
			setUserHistoryRecords(resp.userHistory);
			setTotalRecordCount(resp.totalCount);
		} else {
			setUserHistoryRecords([]);
			setTotalRecordCount(0);
			if (resp?.userErrors?.length) {
				setErrors(resp.userErrors)
			}
		}
		setIsFetching(false);
	}

	const renderView = () => {
		if(isInitialLoading) {
			return <></>;
		}

		if (!isFetching && errors?.length && errors.some(e => e.toLowerCase().includes("unauthorized"))) {
			navigate('/errors/not-found');
		}

		return (
				<div>
					{
						ribbonDataInfo &&
						<HeaderRibbon.Container>
							<HeaderRibbon companyRibbon={{
								tags: ribbonDataInfo.companyTags.map(tag => ({...tag, tagClipboardValue: tag.tagClipBoardValue})),
								changeCompanyLink: urls.companyPick,
								changeEmployeeLink: urls.employeePick
								}}
							/>
						</HeaderRibbon.Container>
					}
					<PageHeader>User History</PageHeader>
					<br />
					<br />
					<DatePicker
						id="datePickStart"
						id2="datePickEnd"
						label="Select Date Range"
						onBlur={() => void 0}
						hasTwoDates={true}
						onChange={(e: Date | null) => {
							if (!e || !(e instanceof Date)) {
								e = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), 1);
							}
							setSelectedStartDate(e);
						}}
						onChange2={(e: Date | null) => {
							if (!e || !(e instanceof Date)) {
								e = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), 1);
							}
							setSelectedEndDate(e);
						}}
						selected={(selectedStartDate instanceof Date) ? selectedStartDate : undefined}
						selected2={(selectedEndDate instanceof Date) ? selectedEndDate : undefined}
						value={(selectedStartDate instanceof Date) ? selectedStartDate.toLocaleDateString("en-us") : "mm/dd/yyyy"}
						isSubmitted={false}
						touched={false}
						dataQa={(selectedStartDate instanceof Date) ? selectedStartDate.toLocaleDateString("en-us") : ""}
						size="small"
						dateFormat={"MM/dd/yyyy"}
						disabled={isFetching}
						errorId="datePickStartDateError"
						isOptional={false}
						minDate={new Date("01/01/1900")}
						maxDate={new Date("06/06/2079")}
					/>
					<PillButton id="btnSearch" onClick={handleSearch} className="search-btn" color="blue" isDisabled={isFetching}>Search</PillButton>
					<br />
					<br />
					{isFetching ? <div className="loading-container"><Spinner /></div> : null}
					{!isFetching && errors?.length ? errors.map((err: string, i: number) => (<div key={i}>{err}</div>)) : null}
					{!isFetching && !errors?.length && !userHistoryRecords?.length ? <div>No Results</div> : null}
					{
						!isFetching && userHistoryRecords?.length ?
						<>
							<div className="row justify-content-between align-items-center">
								<div className="col-auto">Results</div>
								<div className="page-size-select col-auto row align-items-center">
									<div className="col-auto">Show</div>
									<FormSelect
										label={"Page Size"}
										className="col-auto"
										isLabelHidden={true}
										onChange={(e) => {handlePageSizeChange(e.target.value)}} 
										value={selectedPageSize ? selectedPageSize.toString() : ""}
										dataQa={"page-size-select"}
										disabled={isFetching} 
										id="pageSizeSelect">
											<option value="">All</option>
											<option value="5">5</option>
											<option value="10">10</option>
											<option value="25">25</option>
											<option value="50">50</option>
											<option value="100">100</option>
										</FormSelect>
								</div>
							</div>
							<Container className="table-container">
								<Table striped bordered hover>
									<thead>
										<tr>
											<th style={{textAlign: "left", minWidth: 300}}>Activity By</th>
											<th style={{textAlign: "left", width: "100%"}}>Activity</th>
											<th style={{textAlign: "left", minWidth: 200}}>&nbsp;</th>
										</tr>
									</thead>
									<tbody>
										{
											userHistoryRecords.sort((a,b) => new Date(b.actionDate).getTime() - new Date(a.actionDate).getTime())
												.map(
													uh =>
													<tr key={uh.userHistoryId} data-historyid={uh.userHistoryId}>
														<td style={{textAlign: "left", minWidth: 300}}>
															<strong>{uh.modifiedByUserName}</strong>
															<br />
															<span>{uh.modifiedByUserTypeDescription}</span>
														</td>
														<td style={{textAlign: "left"}}>
															<strong>{uh.action.replace(/(\sat)*\sIP\s[0-9]+\.[0-9]+\.[0-9]+\.[0-9]+/, "").replace(/\s*(edited)\sby\s[^\s]+\suser\s"[^"]+"/i, " Edited").replace(/\s*(created)\sby\s[^\s]+\suser\s"[^"]+"/i, " Created")}</strong>
															<br />
															<p dangerouslySetInnerHTML={{ __html: uh.detail.replace("\r", "<br />") }}></p>
														</td>
														<td style={{textAlign: "left", minWidth: 200}}>
															{/* <strong>{displayDate(uh.actionDate)}</strong> */}
															<strong dangerouslySetInnerHTML={{__html: displayDate(uh.actionDate)}}></strong>
															{
																uh.action.match(/\s(IP\s[0-9]+\.[0-9]+\.[0-9]+\.[0-9]+)/)?.length &&
																<>
																	<br />
																	<span>{uh.action.match(/\s(IP\s[0-9]+\.[0-9]+\.[0-9]+\.[0-9]+)/)![0].trim()}</span>
																</>
															}
														</td>
													</tr>
												)
										}
									</tbody>
								</Table>
								<TablePagination activePaginationNumber={selectedPageNumber} setActivePaginationNumber={handlePaginationChange} numberOfFilesPerPage={selectedPageSize ?? totalRecordCount} totalFiles={totalRecordCount} />
							</Container>
						</> :
						null
					}
					<NavFooter
						className="settings-nav"
						leftButtons={
							<>
								{
									userTypeParam?.toLowerCase().includes("company") ?
										<NavFooter.Button id="btnBack" buttonType="Back" href={`${urls.tbhBaseUrl}/MenuAdmin.cfm`}/>
									:
										<NavFooter.Button id="btnBack" buttonType="Back" href={`${urls.tbhBaseUrl}/MenuSystem.cfm`}/>
								}
							</>
						}
						/>	
				</div>	
		); }
	return (
		<>
			{renderView()}
		</>
	);
}

export default UserHistory;